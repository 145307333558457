import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function SolutionsPage() {
    const [width, setWidth] = useState<number>(0);
    const { t } = useTranslation(['solutions', 'common']);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Soluções - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <div className="container">
            <div>
                <div className="contentPageContainer">
                    {width > 845 ? (
                        <img alt="imagem ilustrativa homem no mercado" src={require('../../assets/Picture3.png')} />
                    ) : (
                        <div />
                    )}
                    <div className="container-grande">
                        <h4>{t('page_name')}</h4>
                        <p>{t('textSolution1')}</p>
                        <p>{t('textSolution2')}</p>
                        <p>{t('textSolution3')}</p>
                        <p>{t('textSolution4')}</p>
                        <p>{t('textSolution5')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SolutionsPage;
