import { Modal, Tab } from '@scuf/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import 'styles/repaircenter.scss';
import 'styles/spareParts.scss';
import SparePartsReport from './components/tabs/sparePartsReport';
import SparePartsSearch from './components/tabs/sparePartsSearch';
import { SparePartsAdminChat } from './components/tabs';
import useUser from 'helpers/hooks/useUser';
import useFetch from 'helpers/hooks/useFetch';
import { SparePartsChatTable } from 'models/repaircenter/detailsRma';
import { SPARE_PARTS } from 'resources';
import { useRecoilState } from 'recoil';
import { ChatSpareParts } from 'resources/atoms/spareParts';

function SpareParts() {
    const [, setWidth] = useState<number>(0);
    const { user } = useUser();
    const { t } = useTranslation(['spareparts', 'common']);
    const [, setChatsSpareParts] = useRecoilState<SparePartsChatTable[]>(ChatSpareParts);
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(true);
    const [chats, loadChats] = useFetch<{ roleId: number; ssoId: string; companyId: number }, SparePartsChatTable[]>({
        url: SPARE_PARTS.getAllChats
    });

    useEffect(() => {
        UnreadMessage();
        setChatsSpareParts(chats?.data?.map((e: SparePartsChatTable) => ({ ...e, order: 'teste' }))!);
    }, [chats]);

    useEffect(() => {
        loadChats({ roleId: parseInt(user?.ROLE!), ssoId: user?.SSOID!, companyId: user?.COMPANY! });
        setChatsSpareParts(chats?.data?.map((e: SparePartsChatTable) => ({ ...e }))!);
        UnreadMessage();
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    const UnreadMessage = () => {
        chats?.data?.forEach(e => {
            if (user?.ROLE === '1' || user?.ROLE === '2') {
                if (e.itWasReadByAdmin === false) {
                    setUnreadMessage(unreadMessage => unreadMessage + 1);
                }
            } else if (user?.ROLE === '3' || user?.ROLE === '4') {
                if (e.itWasReadByCustomer === false) {
                    setUnreadMessage(messageRead => messageRead + 1);
                }
            }
        });
    };

    const handleOverlayClick = (event: any) => {
        if (event.target.className === 'overlay') {
            setOpenModal(false);
        }
    };

    const renderPromotionModal = () => {
        return (
            <div className="overlay-wrapper">
                <div className="overlay" onClick={handleOverlayClick}>
                    <img alt="imagem promocao" src={require('../../assets/PromocaoSpareParts.jpg')} className="promotion-image" />
                </div>
            </div>
        );
    };

    return (
        <>
            {
                //Comente aqui para desaparecer o banner
                openModal && renderPromotionModal()
            }
            <div className="container wrap-content">
                <Tab defaultActiveIndex={0} className="tab-with-divider">
                    <Tab.Pane title={t('page_name')}>
                        <SparePartsSearch />
                    </Tab.Pane>
                    <Tab.Pane title={t('tabs.Report')}>
                        <SparePartsReport />
                    </Tab.Pane>
                    <Tab.Pane title={t('common:tabs.Chat')} badge={unreadMessage}>
                        <SparePartsAdminChat />
                    </Tab.Pane>
                </Tab>
            </div>
        </>
    );
}

export default SpareParts;
